.noselect() {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                   supported by Chrome, Edge, Opera and Firefox */
}

.ant-form-item-with-help,
.ant-form-item-has-error {
  .ant-plus-input-wrapper {
    .floating-label {
      color: @error-color !important;
    }
  }
}

.ant-plus-input-wrapper .ant-input-number + .floating-label {
  color: #777;
}

.ant-plus-input-wrapper .ant-input:not(textarea) + .floating-label {
  color: #777;
}

.ant-plus-input-wrapper {
  position: relative;
  // input
  &.is-floating .ant-input + .floating-label,
  &.is-floating .ant-input-number + .floating-label,
  &.is-floating .ant-select + .floating-label,
  &.is-floating .ant-picker + .floating-label,
    // input-number
  .ant-input:focus + .floating-label,
  .ant-input-number.ant-input-number-focused + .floating-label,
  .ant-select.ant-select-focused + .floating-label,
  .ant-picker.ant-picker-focused + .floating-label {
    top: 0;
    transform: scale(0.8) translate(1px, -55%);
    //background: white;
    .required-asterisk {
      color: @error-color;
    }
  }

  // input
  &.is-floating .ant-input:hover + .floating-label,
  &.is-floating .ant-input-number:hover + .floating-label,
    // input-number
  .ant-input:focus + .floating-label,
  .ant-input-number.ant-input-number-focused + .floating-label,
  .ant-select.ant-select-focused + .floating-label,
  .ant-picker.ant-picker-focused + .floating-label {
    color: @primary-color;
  }

  .ant-input-sm + .floating-label,
  .ant-input-number-sm + .floating-label {
    left: @input-padding-horizontal-sm - 4px + 1px;
    top: @input-height-sm / 2;
  }

  .ant-input-lg + .floating-label,
  .ant-input-number-lg + .floating-label {
    left: @input-padding-horizontal-lg - 4px + 1px;
    top: @input-height-lg / 2;
  }

  .ant-input:not(textarea) + .floating-label,
  .ant-input-number + .floating-label {
    top: 50%;
  }

  .floating-label {
    .noselect();

    :not(.ant-form-item.ant-form-item-has-feedback.ant-form-item-has-success) & {
      //background: white; @todo: before we can introduce this change I will need to come up with a solution that does not cause negative side-effects.
    }

    pointer-events: none;
    position: absolute;
    left: @input-padding-horizontal-base - 4px + 1px;
    top: 19px;
    transform: translateY(-50%);
    color: @text-color-secondary;
    font-size: @font-size-base;
    line-height: 1;
    transform-origin: left top;
    transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
    cursor: text;
    background: white;
    padding: 4px;

    .required-asterisk {
      color: inherit;
    }
  }
}

.ant-plus-input-wrapper .floating-label {
  color: #777;
}
