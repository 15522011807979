@import "customized-antd-theme";
@import "~antd/lib/style/mixins/index";
@import "~antd/lib/style/core/base";
// @todo: add antd's globals manually and safely from -> "~antd/lib/style/core/global";
@import "~antd/lib/style/core/iconfont";
@import "~antd/lib/style/core/motion";

@import "~antd/lib/affix/style/index.less";
@import "~antd/lib/alert/style/index.less";
@import "~antd/lib/anchor/style/index.less";
@import "~antd/lib/avatar/style/index.less";
@import "~antd/lib/breadcrumb/style/index.less";
@import "~antd/lib/card/style/index.less";
@import "~antd/lib/badge/style/index.less";
@import "~antd/lib/back-top/style/index.less";
@import "~antd/lib/calendar/style/index.less";
@import "~antd/lib/button/style/index.less";
@import "~antd/lib/carousel/style/index.less";
@import "~antd/lib/cascader/style/index.less";
@import "~antd/lib/comment/style/index.less";
@import "~antd/lib/collapse/style/index.less";
@import "~antd/lib/image/style/index.less";
@import "~antd/lib/config-provider/style/index.less";
@import "~antd/lib/checkbox/style/index.less";
@import "~antd/lib/dropdown/style/index.less";
@import "~antd/lib/drawer/style/index.less";
@import "~antd/lib/divider/style/index.less";
@import "~antd/lib/grid/style/index.less";
@import "~antd/lib/empty/style/index.less";
@import "~antd/lib/layout/style/index.less";
@import "~antd/lib/input-number/style/index.less";
@import "~antd/lib/mentions/style/index.less";
@import "~antd/lib/list/style/index.less";
@import "~antd/lib/menu/style/index.less";
@import "~antd/lib/modal/style/index.less";
@import "~antd/lib/message/style/index.less";
@import "~antd/lib/notification/style/index.less";
@import "~antd/lib/page-header/style/index.less";
@import "~antd/lib/popconfirm/style/index.less";
@import "~antd/lib/pagination/style/index.less";
@import "~antd/lib/popover/style/index.less";
@import "~antd/lib/progress/style/index.less";
@import "~antd/lib/rate/style/index.less";
@import "~antd/lib/result/style/index.less";
@import "~antd/lib/space/style/index.less";
@import "~antd/lib/radio/style/index.less";
@import "~antd/lib/select/style/index.less";
@import "~antd/lib/slider/style/index.less";
@import "~antd/lib/spin/style/index.less";
@import "~antd/lib/statistic/style/index.less";
@import "~antd/lib/skeleton/style/index.less";
@import "~antd/lib/steps/style/index.less";
@import "~antd/lib/switch/style/index.less";
@import "~antd/lib/tag/style/index.less";
@import "~antd/lib/tooltip/style/index.less";
@import "~antd/lib/time-picker/style/index.less";
@import "~antd/lib/timeline/style/index.less";
@import "~antd/lib/typography/style/index.less";
@import "~antd/lib/upload/style/index.less";
@import "~antd/lib/auto-complete/style/index.less";
@import "~antd/lib/descriptions/style/index.less";
@import "~antd/lib/form/style/index.less";
@import "~antd/lib/locale-provider/style/index.less";
@import "~antd/lib/tabs/style/index.less";
@import "~antd/lib/transfer/style/index.less";
@import "~antd/lib/tree/style/index.less";
@import "~antd/lib/tree-select/style/index.less";
@import "~antd/lib/input/style/index.less";
@import "~antd/lib/icon/style/index.less";
@import "~antd/lib/table/style/index.less";
@import "~antd/lib/date-picker/style/index.less";

// Custom styles
@import 'antd-safe-globals';
@import 'components';

.ant-form-large .ant-form-item-label > label {
  min-height: 40px;
  height: auto;
}

.ant-form-item-label label {
  height: auto;
  min-height: 32px;
  text-align: left;
  white-space: normal;
}
