.ant-form-item {
  padding: 0 0 7px 0;

  &.ant-form-item-has-feedback.ant-form-item-has-success {
    input, div.ant-select-selector, div.ant-input-number-handler-wrap, div.ant-picker {
      // background: #DFEBFE; @todo: before we can introduce this change I will need to come up with a solution that does not cause negative side-effects.
    }

    .ant-form-item-children-icon {
      color: @blue-base;
    }
  }
}

.ant-form-item-label > label::after {
  content: ''
}
