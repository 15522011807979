@import "~antd/lib/style/themes/default";

// COMMON
@border-radius-base: 4px;

// COLOURS
@primary-color: #434F5C;
@blue-base: #357FFA;
@red-base: #C24040;

// FONTS
@font-size-base: 16px;

@font-family: Source Sans Pro, sans-serif;

// LINKS
@link-hover-color: "inherit";
@link-active-color: "inherit";

// INPUTS
@input-placeholder-color: #767676;
@link-decoration: 'underline';

// BUTTON
@btn-primary-bg: #357FFA;

// Forms

input,
button,
select,
optgroup,
textarea {
  font-family: @font-family !important;
}
