.ant-input::-webkit-input-placeholder {
  color: @input-placeholder-color;
}
.ant-input:-ms-input-placeholder {
  color: @input-placeholder-color;
}
.floating-label::-ms-input-placeholder {
  color: @input-placeholder-color;
}
.floating-label::placeholder {
  color: @input-placeholder-color;
}
.ant-input:-moz-placeholder-shown {
  text-overflow: ellipsis;
}
.ant-input:-ms-input-placeholder {
  text-overflow: ellipsis;
}
.ant-input:placeholder-shown {
  text-overflow: ellipsis;
}
